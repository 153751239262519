$grid-columns: 20;

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'pod-styles';
@import 'ember-searchable-select/style';
// Chat
@import 'ember-power-select';

@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'PT Sans', sans-serif;
  // font-family: var(--font);
  // --font: 'PT Sans';
  -ms-overflow-style: none;
  scrollbar-width: none;
}
[data-ember-action] {
  cursor: pointer;
}
*::-webkit-scrollbar {
  display: none;
}
*::scrollbar {
  display: none;
}
h2,
.h2,
h1,
.h1,
h3,
.h3 {
  font-size: initial;
}
html,
body{
  height: 100%;
}
.vid1-dimensions {
  height: 735px;
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.ember-tooltip[x-placement^='bottom'] .ember-tooltip-arrow {
  border-bottom-color: #eee;
}
.ember-tooltip[x-placement^='left'] .ember-tooltip-arrow {
  border-left-color: #eee;
}
.ember-tooltip[x-placement^='right'] .ember-tooltip-arrow {
  border-right-color: #eee;
}
.ember-tooltip[x-placement^='top'] .ember-tooltip-arrow {
  border-top-color: #eee;
}

.devider {
  background: rgba(255, 255, 255, 0.3);
  height: 1px;
  width: 100%;
  margin: 10px 0px;
}
.ember-tooltip {
  background: #eee;
  color: black;
  .tooltip-inner {
    background: #eee;
    color: black;
  }
}
.module-layout {
  height: 100%;
  width: 100%;
}
.single-link {
  border-radius: 0 .25rem .25rem 0;
  border: none;
  min-height: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  background: var(--text-color-akzent);
  padding-left: 8px;
}
.streampraefix {
  border-radius: 0.25rem 0 0 0.25rem;
  background: var(--text-color-akzent);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-right: 1px solid white;
  padding: 0px 10px;
  color: white;
}
.videomenu-item.mr-0 {
  margin-left: 0px !important;
}
.stream-select {
  width: 240px;
  margin-right: 6px;
  div {
    background: var(--text-color-akzent);
  }
  .ember-basic-dropdown-trigger {
    border-radius: 0 0.25rem 0.25rem 0;
    border: none;
    min-height: 32px;
    color: white;
    display: flex;
    align-items: center;
  }
  .ember-power-select-status-icon {
    right: 8px;
    border-color: white transparent transparent transparent;
  }
}
.ember-power-select-dropdown {
  border-radius: 0px !important;
  border-top: 1px solid white !important;
  border-left: 0px solid #aaaaaa !important;
  border-right: 0px solid #aaaaaa !important;
}
.ember-power-select-option {
  background-color: var(--text-color-akzent) !important;
  color: white;
}
.ember-power-select-option[aria-current='true'] {
  background-color: var(--text-color-akzent) !important;
  color: white;
}
.ember-basic-dropdown-content.ember-power-select-dropdown {
  border-bottom: none !important;
  border-radius: 0 0 0.25rem 0.25rem !important;
}

.ember-power-select-trigger:focus,
.ember-power-select-trigger--active {
  border-top: 0px solid #aaaaaa !important;
  border-bottom: 0px solid #aaaaaa !important;
  border-right: 0px solid #aaaaaa !important;
  border-left: 0px solid #aaaaaa !important;
}
// .menu-padding {
//   padding-right: 40px; // Disabled for now MENU DISABLED
// }
.header-icons {
  position: relative;
  left: 10px;
  width: 100%;
  margin-top: -12px;
}
.logo-header {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-flow: column;

  .logo {
    position: relative;
    padding-left: 10px;
    display: block;
    height: auto;
    width: auto;
  }
}

.menu {
  display: none; // Disabled for now MENU DISABLED
  position: absolute;
  top: 0;
  right: 5px;

  .menu-item {
    height: 30px;
    width: 30px;
    border-radius: 4px;
    padding-left: 9px;
    padding-top: 1px;
    margin-bottom: 5px;
    background: #3333334a;
    border: 1px solid var(--secondary);
    cursor: pointer;
  }
}

.app-bg {
  min-height: 100%;
}

.stream-wrapper {
  position: relative;
}
.h-70 {
  height: 70% !important;
}
.h-30 {
  height: 30% !important;
}
.stream,
.stream-wrapper {
  height: 100%;
}

.videomenu {
  justify-content: flex-end;

  .videomenu-item {
    border-radius: 0.25rem;
    width: 32px;
    height: 32px;
    margin: 0 5px 8px;
    padding: 5px;
    background: var(--text-color-akzent);

    img {
      width: 16px;
      height: 16px;
      margin: 8px;
    }
  }

  .divider {
    border-left: 1px solid #ffffff88;
    width: 1px;
    height: 32px;
    margin: 0 2px;
  }

  a {
    color: var(--text-color);
  }
}

.login-form {
  .login-header {
    text-align: center;
    width: 400px !important;
    left: calc(50% - 200px);
    position: relative;
    margin-bottom: 10px;
  }

  input,
  button {
    border-radius: 0px;
    width: 400px !important;
    left: calc(50% - 200px);
    position: relative;
    margin-bottom: 10px;
  }
}

.login-btns {
  background: var(--text-color-akzent);
  float: right;

  .login-btn {
    border: none;
    padding: 0 15px;
    color: #fff;
    height: 32px;
    outline: none;
    text-decoration: none;
  }

  .chat-btn {
    border-left: 1px solid #ffffff88;
    border-right: none;
    border-bottom: none;
    border-top: none;
    width: 32px;
    height: 32px;
    background: var(--text-color-akzent);
    color: #fff;
    border-radius: 0px;
    outline: none;

    img {
      height: 16px;
      width: 16px;
    }
  }

  .chat-btn:focus {
    outline: none;
  }
}
video {
  width: 100%;
  border: 1px solid var(--secondary);
}

.module-layout {
  position: absolute;
  // right: 40px; // Disabled for now MENU DISABLED
  right: 0px;
  height: 100%;
}

.helper-window {
  position: fixed;
  z-index: 101;
  top: 20%;
  width: 800px;
  background: #333;
  border-radius: 5px;
  padding: 15px;
  left: calc(50% - 400px);
}

.overlay {
  background: #33333388;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer {
  display: flex;
  flex-direction: row-reverse;

  .impressum,
  .datenschutz {
    color: var(--text-color);
    text-decoration: none;
  }
}

.impressum-wrapper,
.datenschutz-wrapper {
  margin-top:80px;
  padding: 20px;

  a:hover {
    text-decoration: none;
  }
}

.back-btn {
  color: #fff;
  height: 32px;
  width: 53px;
  border: none;
  margin: 5px 0;
  padding: 4px 10px;
  text-decoration: none;
  background: var(--text-color-akzent);
}

// CSS Overwrites for event-components

.app-bg {
  background-position-y: 0px !important;
  min-height: calc(100% - 100px);
}

.chat-input-wrapper {
  position: absolute !important;
}
.app-chat-profiles {
  height: calc(100% - 70px) !important;
}
.module-layout {
  .module-wrapper {
    border-radius: 0.25rem;
    nav.header-nav {
      border-radius: 0.25rem 0.25rem 0 0;
      background: var(--text-color-akzent);
      ul {
        margin: 0px;
        padding: 0px;
        overflow-y: hidden;
        overflow-x: scroll;
        li {
          button {
            height: 45px;
            padding: 0px 15px;
            text-transform: capitalize;
            background: none;
          }
        }
      }
    }

    .chat-input-wrapper {
      height: 45px;

      input {
        height: 100%;
        width: 100%;
        margin: 0px;
        border-radius: 0px;
      }

      button {
        border: none;
        height: 100%;
        background: var(--text-color-akzent);
        color: #fff;
        padding: 0 10px;
      }
    }
    .chat-box {
      //border-radius: 0px;
      //box-shadow: none;

      .chat-box-header {
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }
    .chatroom-card {
      .chatroom-card-header {
        padding-top: 10px;
        font-size: 1rem;
      }
    }
  }
}

.twitter-wall-create {
  textarea {
    width: calc(100% - 20px) !important;
    margin: 0 10px;
  }
}
h1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin: 0.83rem 0;
}
h3 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0.83rem 0;
}
.tabs {
  margin: 0 -10px;
  width: calc(100% + 20px) !important;
  margin-bottom: 10px;
}
.app-tabs {
  margin: 0 10px;
}
.magic-container {
  margin: 0 10px;
}
.btn {
  position: relative;
}
.app-chat-profiles .pm-name {
  font-size: 16px;
  font-weight: normal;
  margin-top: -6px;
}
.dashboard-icon,
.dashboard-item-link-chat {
  display: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn.btn-fullwidth {
  width: 100%;
}
.btn.btn-success {
  color: white;
  background-color: #16a34a;
}
.btn-card {
  color: var(--text-color-appointment);
  background-color: var(--background-appointment);
}
.btn.btn-sm {
  font-size: 16px;
  padding: 2px 6px;
  border-radius: 6px;
}
.btn.btn-p {
  margin: 0;
  cursor: initial;
}
.btn .btn-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--ecn-red);
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
  transform: translateX(20%) translateY(-10%);
}

// Twitterwall
.twitter-wall-create {
  position: absolute;
  height: 55px;
}
.app-chat {
  .chat-box {
    padding: 5px;
    width: 80%;
    margin: 10px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0px 0px 20px -10px black;
    display: flex;
    flex-direction: row;
  }
  .chat-box-avatar img {
    max-width: 63px;
    max-height: 63px;
    margin: 5px;
  }
}
#wall {
  padding: 5px 5px 10px;
  min-height: auto;
  overflow: auto;
}

.twitter-wall-create.open {
  top: 30%;
  height: calc(70% - 50px);

  .close-icon {
    cursor: pointer;
  }

  .location-bar {
    display: none;
  }

  .wrapper::before {
    background: none;
  }

  .wrapper {
    top: 70%;
    bottom: auto;
    cursor: pointer;
  }

  button {
    height: 30px;
  }
}

.videochat {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: -500px;
  height: 100%;
  width: 400px;
  transition: all 0.6s ease;
  z-index: 100000;
}

.videochat.unity-open {
  position: fixed;
  width: 450px;
}

// .unity-wrapper #unity-iframe.videochat-open {
//   width: calc(100% - 400px);
//   left: 400px;
//   position: absolute;
// }

// .unity-wrapper.open #unity-iframe.videochat-open {
//   width: calc(100% - 450px);
//   left: 450px;
//   position: absolute;
// }

.videochat.open {
  left: 0px;
}

#nav-slot {
  height: 0px;
}

.location-bar {
  display: none;
}

.chat-box-avatar img {
  border-radius: 0px !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.video-js {
  width: 100%;
}

.app-avatar-module {
  height: calc(100% - 45px) !important;
  overflow: auto;
}
.app-frage-single {
  width: calc(100% - 20px) !important;
}

.unity-wrapper {
  height: 735px;
  width: 100%;

  .unity-menu {
    display: none;
  }

  #unity-iframe {
    height: 100%;
    width: 100%;
  }
}

.unity-wrapper.open {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10001;

  .unity-menu {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 50px;
    background: var(--text-color-akzent);
    height: 100%;

    nav {
      ul {
        flex-direction: column;
        padding: 0px;
      }
    }
  }

  #unity-iframe {
    width: calc(100% - 50px);
  }
}

// Profil styles

.app-avatar-module {
  .app-avatar-photo {
    left: calc(50% - 500px);
    .btn-group-lg {
      left: calc(50% - 127px);
      position: relative;
      button {
        margin: 10px;
      }
    }
  }

  .avatar-container {
    max-height: 200px;

    img {
      max-height: 200px;
    }
  }

  .btn {
    border-radius: 0px;
  }
}

.app-frage-single {
  border-radius: 0px;

  input,
  textarea {
    border-radius: 0px !important;
  }
}

.col-50 {
  width: 50%;
}

.btn-info,
.btn-primary,
.btn-default {
  border: none;
  &:hover {
    background: var(--text-color-akzent);
  }
}
avatar-container {
  display: flex;
  justify-content: flex-end;
}

.avatar-image {
  border-radius: 5px;
  max-width: 100px;
}

.no-avater {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  border: 1px solid var(--text-color-question);
  border-radius: 5px;
}

.edit-avatar {
  color: lightgrey;
  font-size: 10px;
  background: var(--text-color-question);
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 2px;
  border-bottom-right-radius: 5px;
}
.avatar-container {
  display: flex;
  justify-content: flex-end;
}
iframe {
  border: none;
}

.mult-iframe-open {
  position: fixed;
  top: 0;
  left: 0;
  height: 100% !important;
  z-index: 10000;
}
.correct-margin-profil {
  margin-top: 20px;
}

// .app-chat {
//   overflow-y: scroll;
// }
.img-post-inner {
  cursor: pointer;
}

// Chat window position fix
.chat-input-wrapper {
  position: sticky !important;
  transform: none !important;
}

.image-modal {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 9999999999999;
  background: rgba(#000000, 0.8);
  .image-close {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 3rem;
    top: 2rem;
    padding: 0.5rem;
    background-color: black;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      width: 1rem;
      height: auto;
      padding: 0;
    }
    cursor: pointer;
    z-index: 999999999999;
  }
  img {
    height: auto;
    width: calc(100vw - 20px);
    padding: 1rem;
    z-index: 9999999999999;
  }
}

@import 'ember-power-select';

.app-chat {
  position: relative;
  height: 100%;
}

.ember-module.app-chat-profiles {
  height: calc(100% - 50px) !important;
}
.chat-input-wrapper {
  bottom: 0px;
}
.btn-notification {
  display: block;
  background-color: red;
  border-radius: 1.25rem;
  padding: 0.125rem 0.375rem;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(25%, -50%);
}
.pm-messages {
  z-index: 999;
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 25px;
  color: white;
  &:hover {
    cursor: pointer;
  }
  .btn-notification {
    font-size: 10px;
    transform: translate(30%, -6%);
  }
}
.unity-menu .notification-envelope {
  height: 40px;
  width: 30px;
  margin: -2px;
}
.flex {
  display: flex;
}
.mb-4 {
  margin-bottom: calc(4 * var(--base-unit));
}
.mb-8 {
  margin-bottom: calc(8 * var(--base-unit));
}
.icon-mr {
  margin: 0;
  margin-right: calc(2 * var(--base-unit));
}
.icon-ml {
  margin: 0;
  margin-left: calc(2 * var(--base-unit));
}
:root {
  --base-unit: 0.25rem;
}

// missing icons
.fa.fa-clock::before {
  content: '\f017';
}
.fa.fa-envelope::before {
  content: '\f0e0';
}

.user-card {
  width: calc(100% - 20px);
  margin: 0 10px 10px 10px !important;
}
.user-card.close {
  float: unset;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
  color: unset;
  text-shadow: none;
  opacity: 1 !important;
}
.user-card-footer-actions > button {
  color: var(--text-color);
}
.notification-envelope {
  mask-image: url('/icons/icons_nachrichten.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-image: url('/icons/icons_nachrichten.svg');
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  background-color: var(--text-color-action-fields);
  height: 40px;
  width: 40px;
  margin: 0px;
}
.card.card-spectator .card-body {
  flex-direction: column;
  align-items: start;
}
.card.card-spectator .card-body > div:nth-child(2) {
  margin-top: 1rem;
  margin-left: auto;
}
// personal card
.card.card-personal {
  flex-direction: column;
}
.card.card-personal .card-right {
  width: 100%;
  padding: 20px 0 10px 0;
}
.card.card-personal .card-left {
  width: calc(100% + 40px);
  margin: -20px -20px 0px -20px;
  border-top-right-radius: 0.6rem;
  border-bottom-left-radius: 0;
}
.profile-thumb {
  border-radius: 0.5rem;
  overflow: hidden;
}
.is--desktop .app-chat-pm {
  width: auto;
  position: absolute;
  right: 10px;
  left: 10px;
  margin: 0;
  box-shadow: none;
  top: auto;
  bottom: 198px;
  border-radius: 10px;
  background: var(--background-box);
}
.app-chat-profiles {
  div[class^='__app-appointments'] {
    margin: 0 10px;
  }
}
.card.card-spectator .btn-p {
  color: var(--text-color-appointment);
}
.card.card-spectator.card-approved .btn-p {
  color: var(--text-color-box);
}
.card-header {
  background-color: transparent;
}
.card-spectator .card-body {
  align-items: flex-start;
}
.card .btn-success,
.card .btn-danger {
  margin-top: 1rem;
}
.card.card-personal {
  border: none;
  border-radius: 0.6rem;
  .card-actions {
    display: flex;
    flex-wrap: nowrap;
    i {
      color: var(--text-color-appointment);
    }
  }
}
.card.card-spectator,
.card.card-personal {
  .card-body,
  .card-header {
    padding: 0;
  }
}
@media (max-width: 1699.98px) {
  .card.card-appointment .flex {
    flex-wrap: wrap;
  }
  .card.card-appointment .appointment-date-input:nth-child(1) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .card.card-appointment .appointment-date-input:nth-child(2) {
    width: calc(50% - 0.5rem);
    margin-bottom: 1rem;
  }
  .card.card-appointment .appointment-date-input:nth-child(3) {
    width: calc(50% - 0.5rem);
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
