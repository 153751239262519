.__module-wrapper__e9ace {
  height: 100%;

  .module-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--background-nav);
  }

  .ember-module {
    height: calc(100% - 50px) !important;
    height: calc(100% - 50px);
    overflow-y: scroll;
    display: flex;
    flex-flow: column;
  }
}
